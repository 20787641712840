import { push as _push } from 'react-router-redux';

import { addToQuery, removeFromQuery } from 'dpl/util/queryString';
import { pick } from 'dpl/shared/utils';

function push(newQuery, options = { hash: false }) {
  const newLocation = pick(window.location, ['pathname', 'search', 'hash']);

  if (options.hash) {
    newLocation.hash = newQuery;
  } else {
    newLocation.search = newQuery;
  }

  return _push(newLocation);
}

export function pushParams(params = {}, options = { hash: false }) {
  const originalQuery = window.location[options.hash ? 'hash' : 'search'];
  const newQuery = addToQuery(params, originalQuery);
  return push(newQuery, options);
}

export function replaceParams(params = {}, options = { hash: false }) {
  const newQuery = addToQuery(params, '');
  return push(newQuery, options);
}

export function resetParams(...paramNames) {
  const options =
    typeof paramNames[paramNames.length - 1] === 'object'
      ? paramNames.pop()
      : { hash: false };

  const originalQuery = window.location[options.hash ? 'hash' : 'search'];
  const newQuery = removeFromQuery(paramNames, originalQuery);

  return push(newQuery, options);
}
