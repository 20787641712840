import { isPlain } from 'dpl/shared/utils/object';
import allConstraints from 'dpl/shared/validations/constraints';
import validate from 'dpl/shared/validations/customValidate';

export default function createFormSchema(schemaObj, currentPath = '') {
  let retVal = {};

  Object.entries(schemaObj).forEach(([key, value]) => {
    const newPath = currentPath ? `${currentPath}.${key}` : key;

    let _value = value;
    if (_value.validator) {
      _value = value.validator;
      delete _value.validator;
    }

    if (typeof _value === 'string' && _value in allConstraints) {
      retVal[newPath] = Object.entries(allConstraints[_value]).reduce(
        (c, [k, v]) => ({
          ...c,
          [k]: value.fieldName ? { ...v, fieldName: value.fieldName } : v
        }),
        {}
      );
    } else if (key in validate.validators) {
      retVal = {
        ...retVal,
        [currentPath]: { [key]: _value }
      };
    } else if (isPlain(_value)) {
      retVal = {
        ...retVal,
        ...createFormSchema(_value, newPath)
      };
    }
  });

  return retVal;
}
