import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';

import withCurrentUser from 'dpl/decorators/withCurrentUser';
import UserLoginButton from 'dpl/UserAuth/components/UserLoginButton';

function UserLoginButtonContainer(props) {
  if (props.isLoggedIn === false) {
    return <UserLoginButton showSignupText={false} {...props} />;
  }

  return null;
}

UserLoginButtonContainer.propTypes = {
  isLoggedIn: PropTypes.bool
};

UserLoginButtonContainer.defaultProps = {
  isLoggedIn: null
};

export default compose(withCurrentUser)(UserLoginButtonContainer);
