import { isPlain } from 'dpl/shared/utils/object';

export function getStructuredServerErrorsFromResponseJson(json) {
  if (isPlain(json)) {
    const { error_messages: errorMessages, errors, error } = json;

    if (errors) {
      return Object.values(errors).reduce(
        (acc, errorList) => [...acc, ...errorList.map(e => e.message)],
        []
      );
    }

    if (errorMessages) {
      if (isPlain(errorMessages)) {
        return Object.values(errorMessages).reduce(
          (errorsAcc, errorMessage) => [...errorsAcc, errorMessage],
          []
        );
      }

      if (json.error_messages) {
        // can be array or string
        return [].concat(json.error_messages);
      }
    }

    if (error) {
      return [error];
    }
  }

  return ["We're sorry, but something went wrong.", json];
}
