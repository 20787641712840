import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { pushParams } from 'dpl/actions/query_params';
import { SIGNUP_FORM_TYPES } from 'dpl/shared/constants/shared';

const { LOGIN } = SIGNUP_FORM_TYPES;

export default function UserLoginButton({
  className,
  showSignupText,
  onClick
}) {
  const dispatch = useDispatch();
  return (
    <button
      className={classnames('UserLoginButton', className)}
      onClick={
        onClick ??
        (() => dispatch(pushParams({ direct_signup: LOGIN }, { hash: true })))
      }
      data-test-id="header_login_button"
      type="button"
    >
      Log in {showSignupText && '| Join Good Dog'}
    </button>
  );
}

UserLoginButton.propTypes = {
  className: PropTypes.string,
  showSignupText: PropTypes.bool,
  onClick: PropTypes.func
};

UserLoginButton.defaultProps = {
  className: null,
  showSignupText: true,
  onClick: null
};
