import { compose } from 'redux';
import { connect } from 'react-redux';

import __DEPRECATED_defineResources from 'dpl/shared/fetching/deprecated/defineResources';
import { currentUserResourceDefinition } from 'dpl/shared/utils/tptConnectResourceDefinitions';

export default function withCurrentUser(WrappedComponent) {
  return compose(
    __DEPRECATED_defineResources(
      () => ({
        currentUser: {
          ...currentUserResourceDefinition(),
          auto: Boolean(window.__DPL_CURRENT_USER_ID)
        }
      }),
      { pure: false }
    ),
    connect(
      (_state, { currentUser }) => {
        const { meta, value } = currentUser;

        let isLoggedIn = null;
        let isBreeder = null;
        let isBuyer = null;

        if (meta.isError || (meta.isSuccess && !value.data)) {
          isLoggedIn = false;
        } else if (meta.isSuccess) {
          isLoggedIn = true;
          isBreeder = value.data.breeder;
          isBuyer = value.data.buyer;

          if (window.bugsnagClient) {
            window.bugsnagClient.user = {
              ...window.bugsnagClient.user,
              ...value.data
            };
          }
        }

        return {
          isLoggedIn,
          login: currentUser.login,
          isBreeder,
          isBuyer
        };
      },
      null,
      null,
      { pure: false }
    )
  )(WrappedComponent);
}
