import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-restricted-imports
import UserLoginButtonContainer from 'dpl/UserAuth/containers/UserLoginButtonContainer';

export default function EmailUnavailableErrorMessage({ onLoginClick }) {
  return (
    <span>
      This email is already connected to an account.&nbsp;
      <UserLoginButtonContainer
        className="underline fw-medium"
        onClick={onLoginClick}
      />
    </span>
  );
}

EmailUnavailableErrorMessage.propTypes = {
  onLoginClick: PropTypes.func
};

EmailUnavailableErrorMessage.defaultProps = {
  onLoginClick: null
};
