import URL from 'url';

import { stringify } from 'qs';

export const POSSIBLE_URL_REGEX =
  /(\b(?:https?:\/\/|[a-z])\S*\.[a-z]{2,}(?:(?:\/|\?|#)\S*)?\b)/i;
const HTTP_PROTOCOLS_REGEX = /^https?:\/\//;

export function isValidUrl(url) {
  return POSSIBLE_URL_REGEX.test(url);
}

export function normalizeUrl(url, protocolIfMissing = 'http:') {
  const urlObj = URL.parse(url);
  urlObj.slashes = true;
  urlObj.protocol || (urlObj.protocol = protocolIfMissing);
  return urlObj.format().replace('///', '//');
}

/**
 * calls cb on all possible urls; returns textChunk as-is when not a url
 */
export function mapUrlsInText(text, cb) {
  return text.split(POSSIBLE_URL_REGEX).map((textChunk, idx) => {
    if (idx % 2 !== 0) {
      // possible url
      const url = normalizeUrl(textChunk);

      if (HTTP_PROTOCOLS_REGEX.test(url)) {
        return cb(url, textChunk, idx);
      }
    }

    return textChunk;
  });
}

export function stripProtocol(url) {
  return url.replace(HTTP_PROTOCOLS_REGEX, '');
}

// no support for multi-word extensions (eg .co.uk)
export function replaceSubdomain(url, subdomain) {
  const parsed = URL.parse(url);
  const hostParts = parsed.host.split('.');

  if (hostParts.length >= 3) {
    hostParts.shift(); // drop old subdomain
  }

  hostParts.unshift(subdomain);

  return URL.format({
    ...parsed,
    host: hostParts.join('.')
  });
}

const DEFAULT_DISTANCE = 50;

export function coordinatesToParams(coords) {
  return window.encodeURIComponent(
    stringify({
      query: {
        coordinates: {
          formatted_address: coords.displayName,
          distance: DEFAULT_DISTANCE,
          latitude: coords.lat,
          longitude: coords.lng
        }
      }
    })
  );
}
