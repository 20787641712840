import { get } from 'dpl/shared/utils/object';

/**
 * Transforms form array notation into flat indexed object paths.
 *
 * Example:
 *
 * Input:
 *
 * formState: { foo: [{ name: 'a' }, { name: 'b' }] } }
 * fieldName: 'foo[].name'
 * schema: { nonEmpty: true }
 *
 * Output:
 * {
 *   foo.0.name: { nonEmpty: true },
 *   foo.1.name: { nonEmpty: true }
 * }
 *
 */
export const flattenArrayKeys = (() => {
  const arrayKeyRe = /(.+?)\[\]\.?(.*)/;

  return (formState, fieldName, schema) => {
    if (!fieldName.includes('[]')) {
      return { [fieldName]: schema };
    }

    const [, key, rest] = fieldName.match(arrayKeyRe);

    return get(formState, key)?.reduce((carry, value, idx) => {
      if (value?._destroy) {
        return carry;
      }

      const path = [key, idx, rest].filter(k => k !== '').join('.');

      return {
        ...carry,
        ...flattenArrayKeys(formState, path, schema)
      };
    }, {});
  };
})();
