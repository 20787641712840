import { coalesceGet, exists } from 'dpl/shared/utils/object';

export function isTruthy(value) {
  return value === 'true' || value === true;
}

export function isFalsy(value) {
  return value === 'false' || value === false;
}

// From jQuery's src
export function isNumeric(value) {
  // eslint-disable-next-line no-restricted-properties
  return !Number.isNaN(parseFloat(value)) && window.isFinite(value);
}

/**
 * Normalize DOM form values which are converted to string while values in
 * memory (formState) may still be primitives
 */
export function normalizeFormValue(value) {
  if (isNumeric(value)) {
    return Number(value);
  }

  if (isFalsy(value)) {
    return false;
  }

  if (isTruthy(value)) {
    return true;
  }

  return value;
}

export function isEqualFormValues(value1, value2) {
  return (
    value1 === value2 ||
    normalizeFormValue(value1) === normalizeFormValue(value2)
  );
}

/**
 * Given a typical many-to-many association array, returns an array of values
 * from the association's records while keeping same indices
 *
 * Example:
 *
 * Given the arguments:
 *
 *  - associationArray (required):
 *    [{
 *      // indicating a _new_ association to a _persisting_ record
 *      health_test_id: 39
 *    }, {
 *      id: 3,
 *      _destroy: true,
 *      // indicating a _persisting_ association to a _persisting_ record
 *      health_test_attributes: { id: 52 }
 *    }, {
 *      id: 8,
 *      // same as above, but note the missing `_destroy` key
 *      health_test_attributes: { id: 52 }
 *    }, {
 *      // indicating a _new_ association to a _new_ record
 *      health_test_attributes: { name: 'FKA Twigs Hip Test' }
 *    }]
 *
 *  - foreignKeyNamePrefix (optional): 'health_test',
 *  - possibleForeignKeyPaths (optional): [
 *      'health_test_attributes.id',
 *      'health_test_attributes.name'
 *    ],
 *  - filterFn (optional): additional function to filter records by. For example:
 *      assocRecord => assocRecord.type === undefined
 *
 * The return value is: [39, undefined, 52, 'FKA Twigs Hip Test']
 *
 * Note the value at index = 1. It is `undefined` bc the record is marked for
 * destruction.
 *
 */
export function getValuesOfManyToManyAssociationArray(
  associationArray,
  foreignKeyNamePrefix,
  possibleForeignKeyPaths = [],
  filterFn = () => true
) {
  // shifting optional parameters
  if (typeof possibleForeignKeyPaths === 'function') {
    filterFn = possibleForeignKeyPaths;
    possibleForeignKeyPaths = [];
  }

  if (Array.isArray(foreignKeyNamePrefix)) {
    possibleForeignKeyPaths = foreignKeyNamePrefix;
  }

  if (typeof foreignKeyNamePrefix === 'string') {
    possibleForeignKeyPaths = [
      ...possibleForeignKeyPaths,
      `${foreignKeyNamePrefix}_attributes.id`,
      `${foreignKeyNamePrefix}_id`,
      `${foreignKeyNamePrefix}_attributes.name`
    ];
  }

  return associationArray.map(associationRecord => {
    if (associationRecord._destroy || !filterFn(associationRecord)) {
      return undefined;
    }

    return coalesceGet(associationRecord, possibleForeignKeyPaths);
  });
}

/**
 * Given a typical many-to-many association array, returns the first index of
 * the association object using the foreign record's value if found. Otherwise,
 * returns -1:
 */
export function findIdxInManyToManyAssociationArray(
  associationArray,
  foreignValueForLookup,
  foreignKeyNamePrefix,
  possibleForeignKeyPaths,
  filterFn
) {
  const associationValues = getValuesOfManyToManyAssociationArray(
    associationArray,
    foreignKeyNamePrefix,
    possibleForeignKeyPaths,
    filterFn
  );

  return associationValues.findIndex(associationValue =>
    isEqualFormValues(associationValue, foreignValueForLookup)
  );
}

export function unformatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) {
    return '';
  }

  const digits = phoneNumberString.replace(/\D+/g, '');

  // TODO: Temporary until the data on the server is formatted
  // It prevents users with "# or #" style phone numbers from being mutated
  if (digits.length > 10) {
    return phoneNumberString;
  }

  return digits;
}

export function formatPhoneNumber(phoneNumberString) {
  const digits = unformatPhoneNumber(phoneNumberString);

  // TODO: Temporary until the data on the server is formatted
  if (digits.length > 10) {
    return phoneNumberString;
  }

  const [phoneNumber, areaCode, partA, partB, suffix] = digits.match(
    /(\d{0,3})(\d{0,3})(\d{0,4})(.*)/
  );

  if (suffix) {
    return `${areaCode}-${partA}-${partB} ${suffix}`;
  }
  if (partB) {
    return `${areaCode}-${partA}-${partB}`;
  }
  if (partA) {
    return `${areaCode}-${partA}`;
  }

  return phoneNumber;
}

/**
 * Pull out server errors to match validation schema (and input names):
 *
 * In:
 * 'application.user.email': 'email is invalid'
 * --OR--
 * 'application.user.email': [{type: 'invalid', message: 'email is invalid', options: {} }]
 *
 * Out:
 * 'application.user_attributes.email': ['email is invalid']
 */
export function normalizeServerErrors(serverErrorsByAttributeName, formState) {
  return Object.entries(serverErrorsByAttributeName).reduce(
    (c, [attrName, errs]) => {
      if (attrName.includes('.')) {
        const tryAttrName = attrName.replace(
          /(\w+?)\./g,
          (_, nestedAttrName) => `${nestedAttrName}_attributes.`
        );

        if (exists(formState, tryAttrName)) {
          attrName = tryAttrName;
        }
      }

      let messages;
      if (typeof errs === 'string') {
        messages = [errs];
      } else {
        messages = errs.map(err => err.message);
      }

      return { ...c, [attrName]: messages };
    },
    {}
  );
}
