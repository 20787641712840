import validate from 'validate.js';

import customFetch from 'dpl/shared/utils/customFetch';
import {
  CONSTRAINT_MESSAGES,
  EMAIL_CONSTRAINTS,
  EMAIL_VALIDATION_TYPES
} from 'dpl/shared/validations/constraints';
import { isValidUrl } from 'dpl/shared/utils/url';
import { flattenArrayKeys } from 'dpl/shared/validations/utils';

export function sanitizePrice(value) {
  return typeof value === 'string'
    ? parseFloat(value.replace(/\$/g, ''))
    : value;
}

const _convertErrorMessages = validate.convertErrorMessages;

validate.convertErrorMessages = function customConvertErrorMessages(
  errors,
  options
) {
  options.prettify = attribute => {
    const errorInfo = errors.find(e => e.attribute === attribute);
    if (errorInfo && errorInfo.options.fieldName) {
      attribute = errorInfo.options.fieldName;
    }

    return validate.prettify(attribute);
  };

  // delegating call to original function
  const ret = _convertErrorMessages.call(this, errors, options);

  ret.forEach(r => {
    r.error && (r.error = validate.capitalize(r.error));
  });

  return ret;
};

// Custom validators to extend validate.js
validate.validators.truthy = value => {
  if (!value) {
    return CONSTRAINT_MESSAGES.REQUIRED_FIELD;
  }

  return null;
};

validate.validators.price = value => {
  const excludingDollarSign = sanitizePrice(value);

  if (!validate.isNumber(excludingDollarSign)) {
    return `${CONSTRAINT_MESSAGES.INVALID} amount.`;
  }

  return null;
};

validate.validators.boolean = value => {
  if (validate.isBoolean(value) || ['true', 'false'].includes(value)) {
    return null;
  }

  return CONSTRAINT_MESSAGES.REQUIRED_FIELD;
};

validate.validators.optionalFormat = (value, options) => {
  if (!value || options.pattern.test(value)) {
    return null;
  }

  return options.message || CONSTRAINT_MESSAGES.INVALID;
};

validate.validators.inverseFormat = (value, options) => {
  if (value && !options.pattern.test(value)) {
    return null;
  }

  return options.message || CONSTRAINT_MESSAGES.INVALID;
};

validate.validators.url = (value, options) => {
  if (!value || isValidUrl(value)) {
    return null;
  }

  return options.message || `${CONSTRAINT_MESSAGES.INVALID} URL.`;
};

validate.validators.nonEmpty = (value, options = {}) => {
  if (validate.isEmpty(value)) {
    return options.message || `${CONSTRAINT_MESSAGES.REQUIRED_FIELD}`;
  }
  return null;
};

validate.validators.emailValid = (() => {
  const emailToValidity = new Map();

  return value => {
    if (!value) {
      return CONSTRAINT_MESSAGES.REQUIRED_FIELD;
    }

    const formatValidation = validate(
      { email: value },
      {
        email: EMAIL_CONSTRAINTS
      }
    );

    if (formatValidation) {
      return formatValidation.email;
    }

    value = value.toLowerCase().trim();
    if (emailToValidity.has(value)) {
      if (emailToValidity.get(value) === EMAIL_VALIDATION_TYPES.EXISTS) {
        return CONSTRAINT_MESSAGES.EMAIL_UNAVAILABLE;
      } else if (
        emailToValidity.get(value) === EMAIL_VALIDATION_TYPES.INVALID
      ) {
        return CONSTRAINT_MESSAGES.EMAIL_INVALID;
      }
      return null;
    }

    return new validate.Promise(resolve => {
      customFetch(`/api/me/validate_email.json?email=${value}`)
        .then(r => r.json())
        .then(r => {
          const { status } = r;
          if (status === null) {
            resolve();
            emailToValidity.set(value, false);
          } else if (status === EMAIL_VALIDATION_TYPES.EXISTS) {
            resolve(CONSTRAINT_MESSAGES.EMAIL_UNAVAILABLE);
            emailToValidity.set(value, status);
          } else if (status === EMAIL_VALIDATION_TYPES.INVALID) {
            resolve(CONSTRAINT_MESSAGES.EMAIL_INVALID);
            emailToValidity.set(value, status);
          }
        });
    });
  };
})();

const _runValidations = validate.runValidations;

validate.runValidations = function runValidations(
  attributes,
  constraints,
  options
) {
  // transform `foo[].field` to `foo.0.field`, `foo.1.field`
  constraints = Object.entries(constraints).reduce(
    (_constraints, [key, value]) => ({
      ..._constraints,
      ...flattenArrayKeys(attributes, key, value)
    }),
    {}
  );

  return _runValidations.call(this, attributes, constraints, options);
};

export default validate;
