import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQueryClient } from 'react-query';

import useResourceQuery from '../hooks/useResourceQuery';
import useResourceMutation from '../hooks/useResourceMutation';

/* eslint-disable react-hooks/rules-of-hooks */
function normalizeResource(resourceDefinition) {
  const {
    actions = {},
    auto = true,
    createFetchDataConfig = {},
    defaultValue = {},
    params,
    queryKeyPrefix,
    url
  } = resourceDefinition;

  const {
    data,
    isError,
    isFetching,
    isSuccess,
    queryKey,
    refetch,
    dataUpdatedAt
  } = useResourceQuery({
    url,
    params,
    prefix: queryKeyPrefix,
    enabled: Boolean(auto),
    createFetchDataConfig
  });

  const queryClient = useQueryClient();

  const normalizedActions = Object.entries(actions).reduce(
    (accActions, [actionName, actionFn]) => {
      function actionDefinition(...args) {
        if (typeof actionFn === 'function') {
          return actionFn(...args);
        }

        return actionFn;
      }
      // HACK: get tpt-connect options from resource definition first
      let refetchAfter;
      let updateStrategy;
      // ANOTHER HACK: try-catch in case the action tries to access an undefined variable
      try {
        ({ refetchAfter, updateStrategy } = actionDefinition({}));
      } catch (e) {
        refetchAfter = false;
        updateStrategy = false;
      }

      const { mutateAsync } = useResourceMutation({
        queryKey,
        refetchAfter,
        updateStrategy
      });

      return {
        ...accActions,
        [actionName]: (...args) =>
          mutateAsync({ url, ...actionDefinition(...args) })
      };
    },
    {}
  );

  const value = data ? data.data : defaultValue;

  return {
    value,
    meta: {
      isError,
      isFetching,
      isSuccess,
      dataUpdatedAt,
      // DEPRECATED - use dataUpdatedAt directly instead
      lastUpdated: dataUpdatedAt
    },
    fetch: refetch,
    invalidate: () =>
      queryClient.invalidateQueries(queryKey, { refetchActive: false }),
    ...normalizedActions,

    // override withInvalidateAll logic
    invalidateAll: () => {
      // remove queries entirely to simplify withInfiniteScroll interaction
      queryClient.removeQueries(queryKeyPrefix);
      return Promise.resolve(); // keep it thenable
    }
  };
}

export default function __DEPRECATED_defineResources(
  mapStateToResources,
  { pure = true } = {}
) {
  return WrappedComponent => {
    function TptConnectAdapter({ resourceDefinitions, ...rest }) {
      // TODO: memoize
      const resources = Object.entries(resourceDefinitions).reduce(
        (accResources, [key, def]) => ({
          ...accResources,
          [key]: normalizeResource(def)
        }),
        {}
      );

      return <WrappedComponent {...rest} {...resources} />;
    }

    TptConnectAdapter.propTypes = {
      // eslint-disable-next-line react/forbid-prop-types
      resourceDefinitions: PropTypes.object.isRequired
    };

    return connect(
      (state, ownProps) => ({
        resourceDefinitions: mapStateToResources(state, ownProps)
      }),
      null,
      null,
      { pure }
    )(TptConnectAdapter);
  };
}
/* eslint-enable react-hooks/rules-of-hooks */
